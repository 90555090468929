import React from "react";
import { useLocation } from "react-router-dom";
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const predefinedColors = [
  "#125B9A",
  "#F72C5B",
  "#00425A",
  "#E7B10A",
  "#CA7373",
  "#E7FBB4",
  "#FFE31A",
  "#F09319",
  "#FFAD60",
  "#CD5C08",
  "#527853",
  "#E86A33",
  "#365E32",
  "#006769",
  "#4F6F52",
];
