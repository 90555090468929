import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AspectRatio,
  Box,
  Flex,
  HStack,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";

export default function BuildingInfo({ category, onOpen, onOpenVideo }) {
  const firstImageItem = category?.items.find(
    (item) => item.type.toLowerCase() === "image"
  );

  const firstVideoItem = category?.items.find(
    (item) => item.type.toLowerCase() === "video"
  );

  return (
    <AccordionItem
      bg="white"
      boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.15)"
      border="none"
      borderRadius="md"
      mb={4}
    >
      <AccordionButton pb={1}>
        <Box flex="1" textAlign="left">
          {category?.category}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={0}>
        {firstImageItem && (
          <HStack mb={2}>
            <Image
              src={firstImageItem.value}
              alt={firstImageItem.label}
              width="100%"
              height="full"
              borderRadius="md"
              mb={2}
              onClick={onOpen}
            />
          </HStack>
        )}

        {firstVideoItem && (
          <Box mb={4}>
            <AspectRatio
              onClick={onOpenVideo}
              maxW="320px"
              ratio={16 / 9}
              mb={2}
            >
              <iframe
                title={firstVideoItem.label}
                src={firstVideoItem.value}
                allowFullScreen
                style={{ pointerEvents: "none" }}
              />
            </AspectRatio>
          </Box>
        )}

        {category?.items
          .filter((item) => item.type.toLowerCase() === "text")
          .map((item, index) => (
            <React.Fragment key={index}>
              <Flex justifyContent="space-between" py={2} gap={8}>
                <Box minW="30%" maxW="50%">
                  <Text
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    fontSize="small"
                    fontWeight="bold"
                    title={item?.label}
                  >
                    {item?.label}
                  </Text>
                </Box>
                <Text fontSize="small">{item?.description}</Text>
              </Flex>
            </React.Fragment>
          ))}
      </AccordionPanel>
    </AccordionItem>
  );
}
